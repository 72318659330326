<template>
  <div class="home-cont">
    <div class="top-banner">
      <div class="title-big">American Delivery Service </div>
      <div class="title-small">
        <div>Experience hassle-free, timely delivery of your products.
        </div>
        <div>We allow you to add at most 200 packages in one order. </div>
      </div>
      <div class="oprate">
        <div class="oprate-btn" @click="searchTracking">
          <img src="../../assets/images/home/search.svg" alt="">
          <div>Tracking</div>
        </div>
        <div class="oprate-btn" @click="searchQuote">
          <img src="../../assets/images/home/quate.svg" alt="">
          <div>Quotation</div>
        </div>
      </div>
    </div>
    <div style="width:1300px;margin:0 auto;">
      <a-row justify="center">
        <a-col :span="4">
          <div class="image-active">
            <img src="../../assets/images/home/rates.svg" />
            <p>Competitive Rates</p>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="image-active">
            <div>
              <img src="../../assets/images/home/track.svg" />
            </div>
            <p>Easy Tracking</p>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="image-active">
            <div>
              <img src="../../assets/images/home/quick.svg" />
            </div>
            <p>Quick Quotation</p>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="image-active">
            <div>
              <img src="../../assets/images/home/rush.svg" />
            </div>
            <p>Rapid Rush</p>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="image-active">
            <div>
              <img src="../../assets/images/home/service.svg" />
            </div>
            <p>Reliable Service</p>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="introduce">
      <swiper :slides-Per-view="1" :space-between="50" :autoplay="swiperOption.autoplay" :effect="swiperOption.effect"
        @swiper="onSwiper">
        <swiper-slide>
          <div class="pepople-img">
            <img :src="require('../../assets/images/home/competitive.png')" />
          </div>
          <div class="describe">
            <h1>Competitive Rates</h1>
            <p>
              Our philosophy is to provide customers with high-quality services while ensuring that the price is
              the
              lowest among other courier companies.
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="pepople-img">
            <img :src="require('../../assets/images/home/easy.png')" />
          </div>
          <div class="describe">
            <h1>Easy Tracking</h1>
            <p>
              We offer an easy-to-use online query portal. There is no need for you to log in. All you have to do
              is entering the tracking number on the query page and click "search".
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="pepople-img">
            <img :src="require('../../assets/images/home/quick.png')" />
          </div>
          <div class="describe">
            <h1>Quick Quotation</h1>
            <p>
              Our philosophy is to provide customers with high-quality services while ensuring that the price is
              the lowest among other courier companies.
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="pepople-img">
            <img :src="require('../../assets/images/home/rapid.png')" />
          </div>
          <div class="describe">
            <h1>Rapid Rush</h1>
            <p>
              Point-to-point pick-up and delivery service designed for time sensitive business. No other stops, direct and
              to the point destination.
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="pepople-img">
            <img :src="require('../../assets/images/home/reliable.png')" />
          </div>
          <div class="describe">
            <h1>Reliable Service</h1>
            <p>
              Providing top-notch customer service is our number one priority - whether it's ensuring consistently
              fast deliveries, working with you to deliver last-minute urgent packages on time, or helping you
              with any unexpected issues.
            </p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
  <!-- 搜索tracking -->
  <a-modal v-model:visible="visibleTrack" class="tracking" @cancel="trackCancel">
    <template #title>
      Tracking
    </template>
    <template #footer>
      <div class="operate-button">
        <a-space>
          <a-button shape="round" @click="trackReset">Reset</a-button>
          <a-button type="primary" shape="round" @click="trackOk" :loading="searchLoading">Search</a-button>
        </a-space>
      </div>
    </template>
    <div>
      <a-form :model="searchform" ref="trackForm">
        <a-form-item hide-label field="tracking_id">
          <a-input type="text" v-model="searchform.tracking_id" placeholder="Tracking ID">
            <template #suffix>
              <icon-search />
            </template>
          </a-input>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
  <!-- 查询弹框 -->
  <a-drawer :visible="drawerVisible" @cancel="handleCancel" :width="465" :footer="false" :render-to-body="false">
    <template #title>
      <a-space>
        <a-tag color="orange" v-if="searchData.searchValue.category === 'Awaiting Shipment'">{{
          searchData.searchValue.category
        }}</a-tag>
        <a-tag color="blue" v-if="searchData.searchValue.category === 'In Transit'">{{
          searchData.searchValue.category
        }}</a-tag>
        <a-tag color="red" v-if="searchData.searchValue.category === 'Exceptional'">{{
          searchData.searchValue.category
        }}</a-tag>
        <a-tag color="green" v-if="searchData.searchValue.category === 'Delivered'">{{
          searchData.searchValue.category
        }}</a-tag>
        <a-tag color="cyan" v-if="searchData.searchValue.category === 'Dispatching'">{{
          searchData.searchValue.category
        }}</a-tag>
        <a-tag v-if="searchData.searchValue.category === 'Canceled'">{{
          searchData.searchValue.category
        }}</a-tag>
        <a-tag color="purple" v-if="searchData.searchValue.category === 'Callback'">{{
          searchData.searchValue.category
        }}</a-tag>
        <a-tag color="purple" v-if="searchData.searchValue.category === 'Change Address'">{{
          searchData.searchValue.category
        }}</a-tag>
        <span>Tracking :{{ searchData.searchValue.tracking_id }}</span>
        <icon-copy @click="copyVal(searchData.searchValue.tracking_id)" />
      </a-space>
    </template>
    <!-- <div id="map"></div> -->
    <div class="proofImg" v-if="searchData.searchValue.category === 'Delivered' && searchData.searchValue.photograph">
      <div class="proof-til">Proof of receipt</div>
      <img :src="proofReceipt" alt="" />
      <div class="proof-footer">
        <a-space size="large">
          <a target="_blank" :href="proofReceipt + '?' + Math.random"><svg width="12" height="10" viewBox="0 0 12 10"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0.254251 4.69241C1.8668 2.09115 3.7718 0.624756 6.00005 0.624756C8.2283 0.624756 10.1333 2.09115 11.7458 4.69241L11.9364 4.99976L11.7458 5.30711C10.1333 7.90836 8.2283 9.37476 6.00005 9.37476C3.7718 9.37476 1.8668 7.90836 0.254251 5.30711L0.0637207 4.99976L0.254251 4.69241ZM1.4415 4.99976C2.90384 7.23557 4.43236 8.20809 6.00005 8.20809C7.56773 8.20809 9.09626 7.23557 10.5586 4.99976C9.09626 2.76394 7.56773 1.79142 6.00005 1.79142C4.43236 1.79142 2.90384 2.76394 1.4415 4.99976ZM6.00005 4.12476C5.5168 4.12476 5.12505 4.51651 5.12505 4.99976C5.12505 5.48301 5.5168 5.87476 6.00005 5.87476C6.4833 5.87476 6.87505 5.48301 6.87505 4.99976C6.87505 4.51651 6.4833 4.12476 6.00005 4.12476ZM3.95838 4.99976C3.95838 3.87217 4.87247 2.95809 6.00005 2.95809C7.12763 2.95809 8.04171 3.87217 8.04171 4.99976C8.04171 6.12734 7.12763 7.04142 6.00005 7.04142C4.87247 7.04142 3.95838 6.12734 3.95838 4.99976Z"
                fill="white" />
            </svg></a>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"
            style="padding-right:20px;" @click="downloadImg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M5.41667 6.6742V0.458008H6.58333V6.67482L8.23356 5.02459L9.05852 5.84955L6.00031 8.90776L2.9421 5.84955L3.76706 5.02459L5.41667 6.6742ZM1.91667 10.3747V9.20801H0.75V11.5413H11.25V9.20801H10.0833V10.3747H1.91667Z"
              fill="white" />
          </svg>
        </a-space>
      </div>
    </div>
    <a-card style="margin-top:20px;">
      <template #title>
        <!-- Order ID#{{ searchData.searchValue.order_unique_id }}
        <icon-copy @click="copyVal(searchData.searchValue.order_unique_id)" /> -->
        <div style="display:flex;justify-content:space-between;">
          <div>
            Order ID#{{ searchData.searchValue.order_unique_id }}
            <icon-copy @click="copyVal(searchData.searchValue.order_unique_id)" />
          </div>
          <a-button v-if="searchData.searchValue.can_add_note === 1" shape="round" size="mini" @click="openNote">+ Add
            Notes</a-button>
        </div>
      </template>
      <a-timeline>
        <a-timeline-item
          :label="searchData.searchValue.sender.city + ',' + searchData.searchValue.sender.state + ',' + searchData.searchValue.sender.zip + ',' + searchData.searchValue.sender.country"
          lineType="dashed">
          Shipper:
        </a-timeline-item>
        <a-timeline-item lineType="dashed"
          :label="searchData.searchValue.recipient.city + ',' + searchData.searchValue.recipient.state + ',' + searchData.searchValue.recipient.zip + ',' + searchData.searchValue.recipient.country"
          dotColor="#C9CDD4">
          Recipient:
        </a-timeline-item>
      </a-timeline>
      <div class="notice" v-if="searchData.searchValue.notes.length > 0">
        <div class="til">Notes:</div>
        <div class="notice-cont" v-for="note, i in searchData.searchValue.notes" :key="i">
          <div class="who">From: {{ note.from_desc }}</div>
          <div class="cont">{{ note.content }}</div>
          <div class="time">{{ note.created_at }}</div>
        </div>
      </div>
    </a-card>
    <div class="logistics">
      <div class="road-steps">Details of Logistics</div>
      <div class="proof" v-if="searchData.searchValue.category === 'Delivered' && searchData.searchValue.autograph">
        Proof of receipt:
        <a-tooltip content="Download">
          <a-button shape="circle" style="margin-left:10px;" @click="downloadsign">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M4.5 5.57816V0.25H5.5V5.5787L6.91448 4.16422L7.62159 4.87132L5.00027 7.49264L2.37895 4.87132L3.08605 4.16422L4.5 5.57816ZM1.5 8.75V7.75H0.5V9.75H9.5V7.75H8.5V8.75H1.5Z"
                fill="#4E5969" />
            </svg>
          </a-button>
        </a-tooltip>
      </div>
    </div>
    <div class="logs">
      <a-timeline>
        <a-timeline-item v-for="item in  searchData.searchValue.logs" :key="item" :label="item.date" dotColor="#ECECEC">
          {{ item.description }}<br />
          <a-typography-text type="secondary" :style="{ fontSize: '12px', marginTop: '4px' }">{{ item.location }}
          </a-typography-text>
        </a-timeline-item>
      </a-timeline>
    </div>
  </a-drawer>
  <!-- 填写报价弹框 -->
  <a-modal v-model:visible="quoteVisible" title="Calculator" @cancel="quoteCancel">
    <a-form :model="quoteform" autoComplete="off" layout="vertical" ref="quoteForm">
      <a-form-item field="from_zip" label="Shipper Zip" :rules="[
        { required: true, message: 'Please enter zip code.' }
      ]" :validate-trigger="['blur']">
        <a-config-provider :locale="enUS">
          <a-popconfirm content="This is a Popconfirm" position="right" type="warning" :popup-visible="popupvisible"
            ok-text="Confirm" cancel-text="Cancel" @ok="confirm" @cancel="cancelConfirm"
            :content-style="{ width: '276px' }">
            <a-select allow-search placeholder="Please enter" v-model="quoteform.from_zip" @search="fromZipSearch"
              :loading="fromLoading" allow-clear autocomplete="off" :options="fromOptions" @change="selectChange">
              <template #empty>
                <a-empty v-if="(fromIsempty === true)" />
                <div class="empty" v-if="(fromZiperror === true)">
                  <a-space>
                    <icon-info-circle />
                    Zip error
                  </a-space>
                </div>
                <div class="noarea" v-if="(fromNoarea === true)">
                  <icon-empty />
                  Not in the delivery service area
                </div>
              </template>
            </a-select>
            <template #content>
              <div stype="width:375px;">
                Zip: <span>{{ quoteform.from_zip }}</span> cannot be delivered to Zip <span>{{
                  quoteform.to_zip
                }}</span>.
                If you select it, we will reset To Zip.
              </div>
            </template>
          </a-popconfirm>
        </a-config-provider>
      </a-form-item>
      <a-form-item field="to_zip" label="Recipient Zip" :rules="[
            { required: true, message: 'Please enter zip code.' }
          ]" :validate-trigger="['blur']">
        <a-config-provider :locale="enUS">
          <a-select allow-search placeholder="Please enter" v-model="quoteform.to_zip" @search="toZipSearch"
            :loading="toLoading" allow-clear autocomplete="off" :options="toOptions">
            <template #empty>
              <a-empty v-if="(toIsempty === true)" />
              <div class="empty" v-if="(toZiperror === true)">
                <a-space>
                  <icon-info-circle />
                  Zip error
                </a-space>
              </div>
              <div class="noarea" v-if="(toNoarea === true)">
                <icon-empty />
                Not in the delivery service area
              </div>
            </template>
          </a-select>
        </a-config-provider>
      </a-form-item>
      <a-form-item field="length" label="Length" :rules="[
        { required: true, message: 'Please enter length.' }
      ]" :validate-trigger="['blur']">
        <a-input v-model="quoteform.length" placeholder="Please enter" autocomplete="off">
          <template #suffix>
            In
          </template>
        </a-input>
      </a-form-item>
      <a-form-item field="width" label="Width" :rules="[
        { required: true, message: 'Please enter width.' }
      ]" :validate-trigger="['blur']">
        <a-input v-model="quoteform.width" placeholder="Please enter" autocomplete="off">
          <template #suffix>
            In
          </template>
        </a-input>
      </a-form-item>
      <a-form-item field="height" label="Height" :rules="[
        { required: true, message: 'Please enter height.' }
      ]" :validate-trigger="['blur']">
        <a-input v-model="quoteform.height" placeholder="Please enter" autocomplete="off">
          <template #suffix>
            In
          </template>
        </a-input>
      </a-form-item>
      <a-form-item field="weight" label="Weight" :rules="[
        { required: true, message: 'Please enter weight.' }
      ]" :validate-trigger="['blur']">
        <a-input v-model="quoteform.weight" placeholder="Please enter" autocomplete="off">
          <template #suffix>
            Lb
          </template>
        </a-input>
      </a-form-item>
    </a-form>
    <template #footer>
      <div class="operate-button">
        <a-space>
          <a-button shape="round" @click="quoteReset">Reset</a-button>
          <a-button type="primary" shape="round" @click="quoteOk" :loading="quoteLoading">Quotation</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
  <!-- 报价结果 -->
  <a-modal v-model:visible="resultVisible" title="Quotation">
    <a-descriptions :column="1">
      <a-descriptions-item label="Delivery Date">
        {{ quoteData.deliveryDate }}
      </a-descriptions-item>
      <a-descriptions-item label="Service Type">
        {{ quoteData.serviceType }}
      </a-descriptions-item>
      <a-descriptions-item label="List Price">
        ${{ quoteData.listPrice }}
      </a-descriptions-item>
      <a-descriptions-item label="Your Price">
        <span class="your_price">${{ quoteData.userPrice }}</span>
      </a-descriptions-item>
    </a-descriptions>
    <template #footer>
      <div class="operate-button">
        <a-space>
          <a-button shape="round" @click="again">Again</a-button>
          <a-button type="primary" shape="round" @click="copy">Copy</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
  <!-- 添加备注的弹框 -->
  <div id="notePop">
    <a-modal v-model:visible="noteVisible" ok-text="Save" cancel-text="Reset" :render-to-body="false" @cancel="cancelNote"
      :width="520" title="Add Notes">
      <a-form :model="noteForm" ref="noteRef" size="medium" layout="vertical">
        <a-form-item field="phone" label="Recipient Phone Number" :rules="[
          { required: true, message: 'Phone is required' },
          { minLength: 10, maxLength: 15, message: '10-15 characters' }
        ]">
          <a-input v-model="noteForm.phone" placeholder="Required 5-10 characters" />
        </a-form-item>
        <a-form-item field="content" label="Content" :rules="[
          { required: true, message: 'Please enter' }
        ]" :validate-trigger="['blur']">
          <a-textarea v-model="noteForm.content" placeholder="Please enter" allow-clear />
        </a-form-item>
      </a-form>
      <template #footer>
        <div class="change-footer">
          <a-space>
            <a-popconfirm content="Are you sure you want to reset?" type="warning" ok-text="Reset" cancel-text="Cancel"
              @ok="resetNote" class="warnPop">
              <a-button shape="round">Reset</a-button>
            </a-popconfirm>
            <a-button type="primary" shape="round" html-type="submit" @click="submitNote" :loading="noteLoading">Submit
            </a-button>
          </a-space>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script setup>

import { ref, reactive, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { Message } from '@arco-design/web-vue';
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
// 引入api中的接口
import { searchTarckingId, commmonInfo, onlyQuote, getToZipsUnderRange, getFromZipsUnderRange, addNote } from '@/api/homepage.js'
import { getZipCode } from '@/api/order.js'
import {
  IconSearch, IconCopy, IconInfoCircle, IconEmpty
} from '@arco-design/web-vue/es/icon'
import local from '@/utils/local.js'
// 轮播
import { SwiperSlide } from 'swiper/vue/swiper-vue'
import { Swiper } from 'swiper/vue/swiper'
import SwiperCore, { Autoplay, EffectFade } from 'swiper'
import 'swiper/swiper-bundle.min.css'
SwiperCore.use([Autoplay, EffectFade])
const route = useRoute()
const quoteLoading = ref(false)
// 公共信息
async function getConfig () {
  const data = await commmonInfo()
  local.set('configInfo', JSON.stringify(data.data))
}
getConfig()
let swiperOption = reactive({
  autoplay: {
    delay: 3000,
    disableOnInteraction: false
  },
  loop: true,
  speed: 1000,
  effect: 'fade'
})
const status = reactive({
  controlledSwiper: null
})
const onSwiper = (swiper) => {
  status.controlledSwiper = swiper
}
onMounted(() => {
  const swiperBox = document.querySelector('.swiper')
  // 轮播图鼠标移进
  swiperBox.addEventListener('mouseover', () => {
    status.controlledSwiper.autoplay.stop()
  })
  // 轮播图鼠标移出
  swiperBox.addEventListener('mouseout', () => {
    status.controlledSwiper.autoplay.start()
  })
  // 移动到图标
  const iconImg = document.querySelectorAll('.image-active')
  iconImg.forEach((item, i) => {
    item.addEventListener('mouseenter', () => {
      status.controlledSwiper.autoplay.stop()
      status.controlledSwiper.slideTo(i)
    })
    item.addEventListener('mouseleave', () => {
      // 开启轮播
      setTimeout(() => {
        status.controlledSwiper.autoplay.start()
      }, 8000)
    })
  })
  // 快捷操作
  const quations = document.querySelectorAll('.oprate-btn')
  quations[0].addEventListener('mouseover', () => {
    quations[0].firstChild.src = require('../../assets/images/home/hoverTrack.svg')
  })
  quations[0].addEventListener('mouseout', () => {
    quations[0].firstChild.src = require('../../assets/images/home/search.svg')
  })
  quations[1].addEventListener('mouseover', () => {
    quations[1].firstChild.src = require('../../assets/images/home/hoverQuate.svg')
  })
  quations[1].addEventListener('mouseout', () => {
    quations[1].firstChild.src = require('../../assets/images/home/quate.svg')
  })
})
// 搜索----------------------------------------------------------------------------
const visibleTrack = ref(false)
const trackForm = ref(null)
const searchform = reactive({
  tracking_id: ''
})
let searchData = reactive({
  searchValue: {
    sender: {},
    recipient: {},
    notes: []
  }
})

const searchTracking = () => {
  visibleTrack.value = true
  searchform.tracking_id = ''
}
const drawerVisible = ref(false)
// const script = ref('')
// onMounted(() => {
//   script.value = document.createElement('script')
// })
// 签收pingzheng
let proofReceipt = ref('')
const searchLoading = ref(false)
const trackOk = () => {
  trackForm.value.validate(async (value) => {
    if (!value) {
      searchLoading.value = true
      const msg = await searchTarckingId({
        trackingId: searchform.tracking_id
      })
      if (msg.code === 0) {
        searchLoading.value = false
        if (!msg.data.photograph) {
          proofReceipt.value = msg.data.pod_file
        } else {
          proofReceipt.value = msg.data.photograph
        }
        searchData.searchValue = msg.data
        // let lastLocation = msg.data.lastLocation
        // startAddress.value = lastLocation.street1 + ',' + lastLocation.street2 + ',' + lastLocation.city + ',' + lastLocation.state + ',' + lastLocation.zip + ',' + lastLocation.country
        // let endLocation = msg.data.recipient
        // endAddress.value = endLocation.street1 + ',' + endLocation.street2 + ',' + endLocation.city + ',' + endLocation.state + ',' + endLocation.zip + ',' + endLocation.country
        // script.value.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCYZLGxJ3yCFkGJmmT1gOOAh-_7KP1H5f8&callback=initMap&libraries=&v=weekly'
        // script.value.setAttribute('defer', 'defer')
        // document.body.appendChild(script.value)
        // 打开抽屉
        drawerVisible.value = true
        // 清空输入内容
        // searchform.tracking_id = ''
        // 关闭搜索弹框
        visibleTrack.value = false
      } else {
        searchLoading.value = false
        Message.error('Shipment Not found!')
      }
    }
  })
}
// // 起点
// let startAddress = ref('')
// // 终点
// let endAddress = ref('')
// window.initMap = function () {
//   const directionsService = new google.maps.DirectionsService();
//   const directionsRenderer = new google.maps.DirectionsRenderer();
//   const map = new google.maps.Map(document.getElementById("map"), {
//     zoom: 10
//   })
//   directionsRenderer.setMap(map);
//   // 刚进入页面时渲染函数
//   calculateAndDisplayRoute(directionsService, directionsRenderer)
// }
// function calculateAndDisplayRoute (directionsService, directionsRenderer) {
//   directionsService.route({
//     origin: startAddress.value,
//     destination: endAddress.value,
//     optimizeWaypoints: true,
//     travelMode: google.maps.TravelMode.DRIVING
//   }, (response, status) => {
//     if (status === "OK") {
//       directionsRenderer.setDirections(response)
//     }
//   }
//   )
// }
const trackReset = () => {
  trackForm.value.resetFields()
}
// 关闭抽屉
const handleCancel = () => {
  drawerVisible.value = false;
}
const trackCancel = () => {
  searchform.tracking_id = ''
}

// 从后台管理系统  跳转到商家
const shipgateway = async () => {
  const msg = await searchTarckingId({
    trackingId: searchId
  })
  if (msg.code === 0) {
    if (!msg.data.photograph) {
      proofReceipt.value = msg.data.autograph
    } else {
      proofReceipt.value = msg.data.photograph
    }
    searchData.searchValue = msg.data
    // let lastLocation = msg.data.lastLocation
    // startAddress.value = lastLocation.street1 + ',' + lastLocation.street2 + ',' + lastLocation.city + ',' + lastLocation.state + ',' + lastLocation.zip + ',' + lastLocation.country
    // let endLocation = msg.data.recipient
    // endAddress.value = endLocation.street1 + ',' + endLocation.street2 + ',' + endLocation.city + ',' + endLocation.state + ',' + endLocation.zip + ',' + endLocation.country
    // script.value.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCYZLGxJ3yCFkGJmmT1gOOAh-_7KP1H5f8&callback=initMap&libraries=&v=weekly'
    // script.value.setAttribute('defer', 'defer')
    // document.body.appendChild(script.value)
    // 打开抽屉
    drawerVisible.value = true
  } else {
    Message.error('Shipment Not found!')
  }
}
const searchId = route.query.tracking_id
if (searchId) {
  shipgateway()
}
// 报价-------------------------------------------------------------------
const quoteVisible = ref(false)
const quoteForm = ref(null)
const searchQuote = () => {
  quoteVisible.value = true
}
const quoteform = reactive({
  from_zip: '',
  to_zip: '',
  length: '',
  width: '',
  height: '',
  weight: ''
})
const quoteReset = () => {
  quoteForm.value.resetFields()
}
const quoteData = ref({})
const resultVisible = ref(false)
const quoteOk = () => {
  quoteLoading.value = true
  quoteForm.value.validate(async (value) => {
    if (!value) {
      const msg = await onlyQuote({
        from_zip: quoteform.from_zip,
        to_zip: quoteform.to_zip,
        length: quoteform.length,
        width: quoteform.width,
        height: quoteform.height,
        weight: quoteform.weight
      })
      if (msg.code === 0) {
        quoteLoading.value = false
        quoteVisible.value = false
        resultVisible.value = true
        quoteForm.value.resetFields()
        quoteData.value = msg.data
      } else {
        quoteLoading.value = false
        Message.error(msg.message)
      }
    }
  })
}
// recipient zip 模糊查询
const fromOptions = ref([])
const fromLoading = ref(false)
const toOptions = ref([])
const toLoading = ref(false)
const popupvisible = ref(false)
const fromNoarea = ref(false)
const fromIsempty = ref(true)
const fromZiperror = ref(false)
const fromZipSearch = async (value) => {
  if (!value) {
    fromIsempty.value = true
    fromNoarea.value = false
    fromZiperror.value = false
  } else {
    fromIsempty.value = false
  }
  if (!numReg.test(value)) {
    fromZiperror.value = true
    fromNoarea.value = false
  } else {
    fromZiperror.value = false
    if (value.length > 2) {
      fromLoading.value = true
      const msg = await getZipCode({
        term: value
      })
      if (msg.data.length > 0) {
        fromNoarea.value = false
        fromOptions.value = []
        fromLoading.value = false
        for (let i = 0; i < msg.data.length; i++) {
          fromOptions.value.push(msg.data[i].zip)
        }
      } else {
        fromNoarea.value = true
        fromLoading.value = false
        fromOptions.value = []
      }
    }
  }
}
const selectChange = async (value) => {
  if (quoteform.to_zip !== '') {
    fromLoading.value = true
    const msg = await getFromZipsUnderRange({
      to_zip: quoteform.to_zip,
      term: value
    })
    if (msg.data.length > 0) {
      fromOptions.value = []
      fromLoading.value = false
      for (let i = 0; i < msg.data.length; i++) {
        fromOptions.value.push(msg.data[i].zip)
      }
    } else {
      fromLoading.value = false
      fromOptions.value = []
      popupvisible.value = true
    }
  }
}
const toNoarea = ref(false)
const toIsempty = ref(true)
const toZiperror = ref(false)
const numReg = /^[0-9]*$/
const toZipSearch = async (value) => {
  if (!value) {
    toIsempty.value = true
    toNoarea.value = false
    toZiperror.value = false
  } else {
    toIsempty.value = false
  }
  if (!numReg.test(value)) {
    toZiperror.value = true
    toNoarea.value = false
  } else {
    toZiperror.value = false
    if (value.length > 2) {
      toLoading.value = true
      const msg = await getToZipsUnderRange({
        from_zip: quoteform.from_zip,
        term: value
      })
      if (msg.data.length > 0) {
        toNoarea.value = false
        toOptions.value = []
        toLoading.value = false
        for (let i = 0; i < msg.data.length; i++) {
          fromOptions.value = []
          toOptions.value.push(msg.data[i].zip)
        }
      } else {
        toNoarea.value = true
        toLoading.value = false
        toOptions.value = []
      }
    }
  }
}
const quoteCancel = () => {
  quoteForm.value.resetFields()
}
const confirm = () => {
  toOptions.value = []
  quoteform.to_zip = ''
  popupvisible.value = false
}
const cancelConfirm = () => {
  popupvisible.value = false
}
const again = () => {
  quoteVisible.value = true
  resultVisible.value = false
}
// 点击复制
const copy = () => {
  // 创建一个input框
  const input = document.createElement("input")
  document.body.appendChild(input)
  const val = "Delivery Date:" + quoteData.value.deliveryDate + ',' + 'Service Type:' + quoteData.value.serviceType + ',' + 'List Price:$' + quoteData.value.listPrice + ',' + 'Your Price:$' + quoteData.value.userPrice
  input.setAttribute("value", val)
  input.select()
  if (document.execCommand("copy")) {
    document.execCommand("copy")
    Message.success('Copied.')
  }
  document.body.removeChild(input)
}
// 点击复制
const copyVal = (val) => {
  const input = document.createElement("input")
  document.body.appendChild(input)
  input.setAttribute("value", val)
  input.select()
  if (document.execCommand("copy")) {
    document.execCommand("copy")
    Message.success(val + '  ' + 'copied.')
  }
  document.body.removeChild(input)
}
const downloadsign = () => {
  window.open(proofReceipt.value, '_blank', 'top=0,left=0,width=500,height=880,toolbar=0,resizable=0,statusbar=0,scrollbars=0')
}
const downloadImg = () => {
  let image = new Image()
  image.setAttribute("crossOrigin", "anonymous")
  image.onload = function () {
    let canvas = document.createElement("canvas")
    canvas.width = image.width
    canvas.height = image.height
    let context = canvas.getContext("2d")
    context.drawImage(image, 0, 0, image.width, image.height)
    let url = canvas.toDataURL("image/png")
    let a = document.createElement("a")
    let event = new MouseEvent("click")
    a.download = "POD_" + searchData.searchValue.tracking_id
    a.href = url
    a.dispatchEvent(event)
  }
  image.src = proofReceipt.value + '?' + Math.random
}
// contact
const contactForm = reactive({
  name: '',
  companyName: '',
  email: '',
  phone: '',
  address: '',
  how: '',
  message: ''
})
const contactRef = ref(null)
const contactUs = () => {
  contactRef.value.validate((value) => {
    if (!value) {
      console.log('验证通过')
    }
  })
}
// 添加备注
// const whoVisible = JSON.parse(local.get('configInfo')).shipment_note_visible
const noteVisible = ref(false)
const noteRef = ref(null)
const noteForm = reactive({
  phone: '',
  content: ''
})
const openNote = () => {
  noteVisible.value = true
}
const cancelNote = () => {
  noteVisible.value = false
  noteRef.value.resetFields()
}
const resetNote = () => {
  noteRef.value.resetFields()
}

const noteLoading = ref(false)
const submitNote = () => {
  noteRef.value.validate(async (record) => {
    if (!record) {
      noteLoading.value = true
      const msg = await addNote({
        phone: noteForm.phone,
        tracking_id: searchData.searchValue.tracking_id,
        content: noteForm.content
      })
      console.log(msg)
      if (msg.code === 0) {
        noteLoading.value = false
        Message.success('Submitted.')
        noteVisible.value = false
        noteRef.value.resetFields()
        trackOk()
      } else {
        noteLoading.value = false
        Message.error(msg.message)
      }
    }
  })
}

</script>

<style lang="less" scoped>
.home-cont {
  height: 100%;
  background: #F6FBFF;
}

:deep(.arco-menu-selected-label) {
  bottom: -7px;
}

.empty {
  color: rgb(var(--danger-6));
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
}

.noarea {
  color: var(--color-text-4);
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
}

.topNav {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  img {
    padding-left: 82px;
  }

  .top-btn {
    padding-right: 24px;
  }

  :deep(.arco-tabs-nav::before) {
    background-color: transparent;
  }
}

.top-banner {
  background-image: url('../../assets/images/home/Frame3090.svg');
  height: 540px;
  box-sizing: border-box;
  position: relative;

  .title-big {
    font-weight: 500;
    font-size: 56px;
    color: #fff;
    text-align: center;
    line-height: 64px;
    padding-top: 166px;
  }

  .title-small {
    margin-top: 28px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    color: #fff;
  }

  .oprate {
    position: absolute;
    width: 475px;
    height: 149px;
    background-color: #fff;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    left: 50%;
    bottom: -32px;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-around;
    align-items: center;

    .oprate-btn {
      text-align: center;
      cursor: pointer;

      div {
        color: var(--color-text-3);
        margin-top: 24px;
      }

      &:hover {
        div {
          margin-top: 0;
          color: #13B966;
        }
      }
    }
  }
}

.arco-row {
  margin-top: 56px;
}

.arco-col {
  text-align: center;
}

.arco-layout-content {
  background: #F6FBFF;
  min-width: 1300px;

  .image-active {
    p {
      margin-top: 25px;
    }

    img {
      padding: 28px;
    }
  }

  .introduce {
    display: flex;
    justify-content: center;
    margin: 40px 0;
    padding: 0 40px;
    width: 1200px;
    margin: 50px auto;
    margin-bottom: 68px;

    .swiper-slide {
      display: flex;
      justify-content: space-between;
      background-color: #F6FBFF;

      .pepople-img {
        width: 50%;
        margin-right: 48px;
        background-color: #F6FBFF;

        img {
          width: 100%;
          height: 300px;
          border-radius: 8px;
        }
      }

      .describe {
        width: 50%;
        height: 300px;
        text-align: center;
        background-color: #F6FBFF;

        h1 {
          font-weight: 300;
          font-size: 44px;
          line-height: 53px;
          color: #333333;
        }

        p {
          font-weight: 400;
          font-size: 24px;
          line-height: 29px;
          color: #7D7D7D;
          text-align: left;
        }
      }
    }
  }
}

.image-active:hover {
  img {
    border-radius: 50%;
    background: #ECFFF1;
  }

  p {
    color: #13B966;
    font-weight: 600;
  }
}

.tracking .arco-form-item {
  margin-bottom: 0;
}

.operate-button {
  text-align: center;
}

.arco-layout-footer {
  height: 89px;
  background: #13B966;
  text-align: center;
  line-height: 89px;
  font-weight: 400;
  font-size: 12px;
  color: #FFF;
  overflow: auto;
  min-width: 1300px;
}

// 抽屉
.status {
  height: 24px;
  text-align: center;
  line-height: 24px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  padding: 1px 8px;
}

.exceptional {
  color: rgb(var(--danger-6));
  background-color: rgb(var(--danger-1));
}

.await-ship {
  color: rgb(var(--warning-6));
  background-color: rgb(var(--warning-1));
}

.transit {
  color: rgb(var(--blue-6));
  background-color: rgb(var(--blue-1));
}

.dlivered {
  color: rgb(var(--success-6));
  background-color: rgb(var(--success-1));
}

.dispatching {
  color: rgb(var(--cyan-6));
  background-color: rgb(var(--cyan-1));
}

.canceled {
  color: var(--color-text-1);
  background-color: var(--color-fill-2);
}

.change {
  color: rgb(var(--purple-6));
  background-color: rgb(var(--purple-1));
}

.proofImg {
  position: relative;

  .proof-til {
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-1);
    padding-bottom: 12px;
  }

  img {
    height: 255px;
    width: 100%;
    border-radius: 12px;
  }

  .proof-footer {
    position: absolute;
    width: 100%;
    height: 50px;
    left: 0;
    bottom: 6px;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
    text-align: right;
    line-height: 50px;
    margin-right: 20px;
    z-index: 2;
    border-radius: 0 0 12px 12px;

    svg {
      cursor: pointer;
    }
  }
}

// 备注
.notice {

  .til {
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-1);
  }

  .notice-cont {
    padding-top: 16px;

    .who {
      margin-bottom: 4px;
      color: var(--color-text-1);
      font-size: 14px;
      line-height: 1.5715;
    }

    .cont {
      font-size: 12px;
      margin-top: 4px;
      color: var(--color-text-2);
      line-height: 1.5715;
    }

    .time {
      color: var(--color-text-3);
      font-size: 12px;
      line-height: 1.667;
    }
  }
}

.change-footer {
  text-align: center;
  width: 100%;
}

// 日志
.logistics {
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;

  .road-steps {
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-1);
  }

  .proof {
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: var(--color-text-3);

    img {
      width: 32px;
      height: 32px;
      padding-left: 8px;
    }
  }
}

:deep(.logs .arco-timeline .arco-timeline-item:first-child .arco-timeline-item-content) {
  font-weight: bold !important;

  .arco-typography {
    font-weight: normal;
  }
}

.proof-title {
  padding: 11px 16px;
}

.content-img {
  position: relative;

  img {
    width: 360px;
    height: 204px;
  }
}

.your_price {
  color: #13B966;
}

// about
.about-item {
  display: flex;
  justify-content: space-between;
  width: 1300px;
  margin: 0 auto;
  padding: 140px 40px 0 40px;
  box-sizing: border-box;

  img {
    height: 400px;
    width: 50%;
  }
}

.about-des {
  height: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #7D7D7D;
    text-align: left;
    margin: 0;
  }
}

.about-us {
  text-align: center;
  padding-top: 100px;
  font-weight: 300;
  font-size: 56px;
  line-height: 53px;
  color: #333333;
}

.coming-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 200px;

  span {
    padding-top: 24px;
    font-weight: 400;
    font-size: 14px;
  }
}

.over-bottom {
  text-align: center;
  font-family: 'PingFang SC';
  font-weight: 300;
  font-size: 36px;
  width: 1250px;
  margin: 0 auto;
  color: #333333;
  padding: 0 20px 68px 20px;
}

.contact-cont {
  width: 1200px;
  margin: 90px auto;
  position: relative;
  padding-left: 150px;
  box-sizing: border-box;

  .contact-msg {
    width: 450px;
    height: 550px;
    background: rgb(var(--primary-6));
    position: absolute;
    left: 0;
    top: 14%;
    z-index: 99;
    border-radius: 12px;
    color: #fff;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, .1);

    .want {
      font-weight: 500;
      font-size: 44px;
      color: #fff;
      padding: 40px;
    }

    .cat-item {
      display: flex;
      align-items: center;
      padding-left: 24px;
      padding-top: 50px;

      img {
        width: 30px;
      }

      div {
        font-weight: 400;
        font-size: 20px;
        margin-left: 16px;
      }
    }
  }

  .contact-form {
    box-shadow: 5px 5px 25px rgba(0, 0, 0, .1);
    background-color: #fff;
    border-radius: 12px;
    padding-left: 40%;
    padding-top: 50px;
    padding-bottom: 30px;
    padding-right: 10%;

    .get-touch {
      font-weight: 700;
      font-size: 24px;
      color: var(--color-text-1);
      margin-bottom: 20px;
    }
  }
}
</style>
